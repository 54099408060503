






























import {
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import { useGetters, useActions, useState, useMutations } from '@u3u/vue-hooks'

import MySidenav from '@/components/my/Sidenav.vue'
import UnderMaintenance from '@/views/UnderMaintenance.vue'

import auth from '@/composables/auth'
import { pascalToKebab } from '@/inc/utils'

const getClassesFromRoute = (name?: string | null) => {
  const classes: string[] = []

  if (!name || !['MyAg', 'MyAgSingle', 'MySignin'].includes(name as string)) {
    classes.push('has-nav')
  }

  if (name) {
    classes.push(pascalToKebab(name))
  }

  return classes
}

export default defineComponent({
  name: 'my-resa',
  components: {
    MySidenav,
    UnderMaintenance,
  },
  setup(_props, ctx) {
    const { $logger } = ctx.root
    const { fetchI18n } = useActions('my', ['fetchI18n'])
    const { i18n } = useState('my', ['i18n'])
    const { refreshUser } = useActions('user', ['refreshUser'])
    // const { user } = useState(['user'])
    const classesFromRoute = ref(getClassesFromRoute(ctx.root.$route.name))
    const mutations = {
      ...useMutations('my', ['START_LOADING', 'STOP_LOADING']),
    }
    const { currentLang } = useGetters(['currentLang'])

    onBeforeMount(() => {
      mutations.START_LOADING()
      console.log('Mounting MyResa')
      fetchI18n()
    })

    watch(currentLang, () => {
      mutations.START_LOADING()
      fetchI18n()
      const myUrl = ctx.root.$route.path
      if (
        !myUrl.includes('/dashboard/') &&
        !myUrl.includes('/signin/') &&
        !myUrl.includes('/signup/') &&
        !myUrl.includes('/reset/') &&
        !myUrl.includes('/ppp/')
      ) {
        refreshUser()
      }
      mutations.STOP_LOADING()
    })

    watch(
      () => ctx.root.$route,
      c => {
        classesFromRoute.value = getClassesFromRoute(c.name)
      }
    )

    return {
      isLogged: auth.isLogged,
      classesFromRoute,
      ...useGetters(['content']),
      ...useGetters('my', ['isLoading']),
      ...useGetters(['chrome']),
      ...useGetters('user', ['isCommune', 'isFetching']),
      i18n,
    }
  },
})
